import { shaderMaterial,Sparkles,useTexture,OrbitControls, useGLTF, Center } from '@react-three/drei'
import portalVertexShader from "./shaders/portal/vertex.glsl"
import portalFragmentShader from "./shaders/portal/fragment.glsl"
import * as THREE from "three"
import { extend, useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import { Perf } from 'r3f-perf'

const PortalMaterial = shaderMaterial(
   
    {
    uTime : 0,
    uColorStart :new THREE.Color('#51d69d'),
    uColorEnd : new THREE.Color('#f3e0ff'),
    },
    portalVertexShader,
    portalFragmentShader,
)


extend({PortalMaterial:PortalMaterial})


export default function Experience()
{
    const {nodes} = useGLTF('./model/portal.glb')
    const bakedTexture = useTexture('./model/baked.jpg')
    // bakedTexture.flipY = false
    const portalMaterial = useRef()

    useFrame((state,delta) => {
        portalMaterial.current.uTime  += delta 


    })


    return <>
        <color  args={[ '#030202' ]} attach="background" />

        <OrbitControls makeDefault />
        {/* <Perf position="top-left" /> */}
        <Center>
            <mesh 
                geometry={ nodes.baked.geometry } 
                >
                <meshBasicMaterial  map={bakedTexture} map-flipY={false} />
            </mesh>
            <mesh 
                geometry={nodes.poleLightA.geometry }  
                position={nodes.poleLightA.position}
                >
                <meshBasicMaterial   color='#fffffd'  /> 
            </mesh>
            <mesh 
                geometry={nodes.poleLightB.geometry }  
                position={nodes.poleLightB.position}
                >
    
                <meshBasicMaterial   color='#fffffd'  /> 
            </mesh>
            <mesh 
                geometry={nodes.portalLight.geometry }  
                position={nodes.portalLight.position}
                rotation-x={Math.PI *0.5}
                >
                <portalMaterial  ref={portalMaterial}  />    
                {/* <shaderMaterial 
                    vertexShader={portalVertexShader}
                    fragmentShader={portalFragmentShader}
                    uniforms={{ 
                        uTime : {value: 0},
                        uColorStart : {value:new THREE.Color('#51d69d')},
                        uColorEnd : {value:new THREE.Color('#f3e0ff')},
                     }}
                    />  */}
            </mesh>

            <Sparkles 
                size={6}
                scale={[4,2,4]}
                position-y={1}
                speed={0.2}
                count={40}
                />
        
        </Center>


    </>
}